.labelName {
   padding-right: 15px;
   text-align: right;
   color: white;
  }

  .badgeNotication {
    font-size: 10px;
    border-radius: 100%;
    background-color: #f7f7f7;
    width: 25px;
    color: #f00;
    font-weight: 900;
    height: 25px;
    position: absolute;

  }

  .numberBadge {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
