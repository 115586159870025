.headerModal {
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  padding: 1em;
}

.fontBold {
  font-weight: bold;
  font-size:1.2em;
}

.fontRead {
  font-size: 1.0em;
  color: grey;
}
