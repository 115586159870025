// Custom styles for this template

.aside-menu {
  .nav-tabs .nav-link,
  .callout {
    border-radius: 0;
  }
}

.sidebar {
  .sidebar-nav, ul.nav {
    width: $sidebar-width - 16px;
  }
}
.navbar-nav .nav-link {
  color: #ffffff;
}

.btn-outline-secondary {
  color: #7e7d7d;
  border-color: #7e7d7d;
}

// Here you can add other styles
