// Custom variables
@import "variables";

// Import styles with default layout.
@import "node_modules/@coreui/coreui-pro/scss/coreui";

// If you want to add something do it here
@import "custom";

.tableTotal {
  font-size: 15px;
}

.titlePage {
  font-size: 25px;
  font-weight: bold;
  padding-left: 20px;
  padding-bottom: 5px;
}

.filterList {
  margin-left: 15px;
  margin-right: 15px;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #000000;
  font-weight: bold;
}
